import axios from 'axios';

const NEXT_PUBLIC_ACHELOUS_URL = process.env.NEXT_PUBLIC_ACHELOUS_URL;

export const client = axios.create({
  baseURL: NEXT_PUBLIC_ACHELOUS_URL,
});

export const sendEvent = (eventType: string, data: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.dir({ eventType, ...data });
  }
  client.post('/api/events', { type: eventType, data });
};
