import Cookies from 'js-cookie';

import { useSession } from './useSession';
import { getStatisticsConsent } from 'util/consent-service';
import { sendEvent } from 'util/achelousClient';

const browserSize = (width: number, height: number): string => `${width}x${height}`;

const useSendAnalytics = (defaultType?: string, initialData?: Record<string, unknown>) => {
  const { currentUser } = useSession();
  const user = currentUser.data?.user ?? null;

  const sendAnalytics = (type?: string, data?: Record<string, unknown>) => {
    // Check if statistics consent was given
    if (!getStatisticsConsent()) {
      return;
    }

    const finalType = type ?? defaultType;
    if (!finalType) return console.error('No type given!');

    const generalData = {
      userId: user?.uuid ?? null,
      visitorId: Cookies.get('visitor-id'),
      userAgent: navigator.userAgent,
      language: navigator.language,
      screenSize: browserSize(window.screen.availWidth, window.screen.availHeight),
      href: window.location.href,
      referrer: document.referrer,
    };

    if (!user || (user && !user.isAdmin && !user.preventDataCollection)) {
      console.log('Send data', type, { ...data, generalData });
      sendEvent(finalType, { ...initialData, ...data, ...generalData });
    }
  };

  return sendAnalytics;
};

export default useSendAnalytics;
