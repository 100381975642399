'use client';

import { Box, Flex, FlexProps, BoxProps } from '@mezzoforte/forge';

export const SidebarHeader = (props: FlexProps) => (
  <Flex p={3} borderRadius="4px 4px 0px 0px" backgroundColor="backgroundGray" justify="space-between" {...props} />
);

export const SidebarContent = (props: BoxProps) => <Box p={3} {...props} />;

export const SidebarSection = (props: BoxProps) => (
  <Box borderRadius="4px" borderWidth={1} borderColor="lineBorder" overflow="hidden" {...props} />
);

export const SidebarDivider = (props: BoxProps) => (
  <Box mx={-3} mt={3} mb={-3} borderTopWidth={1} borderTopColor="lineBorder" {...props} />
);
