import { EntryUserInfoAPIResponse, SingleBidAPIResponse } from 'types/ApiResponse';
import { Bid } from '../types/bid';
import { priceWithVat } from './price';

export const isUsersBidWinning = (bids: Bid[], bidderId: number | null) => !!bidderId && bids[0]?.bidder === bidderId;

export const isUsersBidSurpassed = (bids: Bid[], bidderId: number | null) => !!bidderId && bids[0]?.bidder !== bidderId;

export const isAutobidActive = (
  userEntryInfo: EntryUserInfoAPIResponse,
  highestBid: number,
  minimumRaise: number,
  vatPerc: number
): boolean => {
  if (!userEntryInfo?.isLoggedIn) return false;
  if (userEntryInfo.biddingBot === null) return false;
  return priceWithVat(userEntryInfo.biddingBot.maxBid, vatPerc) >= priceWithVat(highestBid + minimumRaise, vatPerc);
};

export const bidFromApiResponse =
  (vatPercent: number) =>
  (bid: SingleBidAPIResponse): Bid => ({
    id: bid.id,
    date: new Date(bid.date),
    amount: {
      vatPercent,
      amount: priceWithVat(bid.amount, vatPercent),
    },
    bidder: bid.bidder,
    isAutomatic: bid.isAutomatic,
  });
