import { Container } from 'components/Container/Container';
import { PrismicRichText } from '@prismicio/react';
import { BannerItem, BannerWrapper } from './HeaderTextBannersAnimation';
import { fetchPrismicSettings } from 'prismic/prismicSettings';

export interface HeaderTextBannersProps {
  maxNumItems?: number;
}

export async function HeaderTextBanners({ maxNumItems = 3 }: HeaderTextBannersProps) {
  const prismicSettings = await fetchPrismicSettings();
  const bannerTexts = (prismicSettings?.data.header_info_texts ?? []).slice(0, maxNumItems);
  const displayDuration = prismicSettings?.data.header_info_text_animation_duration ?? 5000;

  if (bannerTexts.length === 0) return null;

  return (
    <BannerWrapper displayDuration={displayDuration} numItems={bannerTexts.length} className="no-print">
      <Container textAlign="center" fontSize="sm" lineHeight="base" fontWeight={500} fontFamily="heading">
        {bannerTexts?.map((text, index) => (
          <PrismicRichText
            field={text.content}
            key={JSON.stringify(text.content)}
            components={{
              paragraph: props => (
                // eslint-disable-next-line react/jsx-no-undef
                <BannerItem index={index} animate={bannerTexts.length > 1}>
                  {props.children}
                </BannerItem>
              ),
            }}
          />
        ))}
      </Container>
    </BannerWrapper>
  );
}
